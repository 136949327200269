(function ($) {
  // Initiate WaveSurfer

  function renderWaveForm (url, parentSelector) {
    const domEl = document.createElement('div');
    console.log('domEl', domEl);

    document.querySelector(parentSelector).appendChild(domEl);

    const wavesurfer = WaveSurfer.create({
      container: domEl,
      waveColor: 'white',
      progressColor: '#ff6600',
      barHeight: 1,
      height: 18,
      cursorWidth: 0,
      responsive: true
    });

    // Load audio file
    wavesurfer.load(url);

    // // Play/Pause on click
    $('.btn').on('click', function () {
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause();
        $('.btn').html('<i class="fas fa-play"></i>');
        console.log('paused');
      } else {
        wavesurfer.play();
        $('.btn').html('<i class="fas fa-pause"></i>');
        $('.audio-wrap').addClass('playing');
        console.log('playing');
      }
    });

    // Control Events Change Icons
    wavesurfer.on('play', function () {
      $('.btn').html('<i class="fas fa-pause"></i>');
      $('.audio-wrap').addClass('playing');
    });

    wavesurfer.on('pause', function () {
      $('.btn').html('<i class="fas fa-play"></i>');
      $('.audio-wrap').removeClass('playing');
    });

    wavesurfer.on('finish', function () {
      $('.btn').html('<i class="fas fa-play"></i>');
      $('.audio-wrap').removeClass('playing');
    });

    // if (wavesurfer.isPlaying()) {
    //   $('.btn').html('<i class="fas fa-pause"></i>');
    //   console.log('playing');
    // } else {
    //   $('.btn').html('<i class="fas fa-play"></i>');
    //   $(".audio-wrap").addClass("playing");
    //   console.log('paused');
    // }

    return wavesurfer;
  };

  const init = function () {
    renderWaveForm('./assets/music/0-Slow-Jam-in-A-Minor.mp3', '#waveform1');
    // renderWaveForm('/assets/music/ChillGroove-EbMaj7.mp3', '#waveform2');
    // renderWaveForm('/assets/music/JeffelsJam.mp3', '#waveform3');
    // renderWaveForm('/assets/music/NotFunk-1.mp3', '#waveform4');
    // renderWaveForm('/assets/music/StillNotFunk-in-Cshm.mp3', '#waveform5');
  };

  $(init);

})(jQuery);
